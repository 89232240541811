<template>
  <div class="incidents">
    <div class="top">
      <!-- Page Header -->
      <div class="page-header">
        <h1 class="page-title">My Tasks</h1>
        <div class="actions">
          <a-button
            icon="phone"
            size="large"
            class="btn-rounded"
            type="default"
            @click.prevent="$router.push('/call-for-help')"
            >Call For Help</a-button
          >
        </div>
      </div>
      <!-- / Page Header -->
    </div>
    <div class="bottom">
      <!-- Loader -->
      <div class="loader" v-if="isLoadingCombined">
        <a-spin></a-spin>
      </div>
      <!-- / Loader -->

      <!-- No tasks -->
      <a-alert
        v-if="!isLoadingCombined && tasks.length === 0"
        type="info"
        message="No tasks have been assigned to you"
      />
      <!-- / No tasks -->

      <!-- Incident Task Groups -->
      <div v-if="!isLoadingCombined">
        <incident-task-group
          v-for="(tasks, incidentId) in tasksGroupedByOwnerId"
          :key="incidentId"
          :incident-id="incidentId"
          :tasks="tasks"
        >
        </incident-task-group>
      </div>
      <!-- / Incident Task Groups -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import IncidentTaskGroup from "./Tasks/IncidentTaskGroup.vue";
const _ = require("lodash");
import RequiresNonHiddenPresences from "../mixins/RequiresNonHiddenPresences";

export default {
  name: "Tasks",
  mixins: [RequiresNonHiddenPresences],
  components: { IncidentTaskGroup },
  created() {
    // this.loadTasks();
  },
  computed: {
    ...mapGetters("tasks", {
      tasks: "tasks",
      isLoading: "isLoading",
    }),

    ...mapGetters("missionControl", {
      organisations: "organisations",
      isLoadingMissionControl: "isLoading",
    }),

    ...mapGetters("incidents", {
      isLoadingIncidents: "isLoading",
    }),

    isLoadingCombined() {
      return (
        this.isLoading ||
        this.isLoadingMissionControl ||
        this.isLoadingIncidents
      );
    },

    tasksGroupedByOwnerId() {
      return _.groupBy(this.tasks, "ownerId");
    },
  },
  methods: {
    ...mapActions("tasks", {
      loadTasks: "loadTasks",
    }),
  },
};
</script>

<style lang="scss" scoped>
.incidents {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  .top {
    flex-shrink: 1;
  }
  .bottom {
    flex-grow: 1;
    overflow-y: scroll;
    // No scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
</style>